.MuiButton-contained:hover {
  background-color: #385f96;
}
.bp-widget-side {
  position: fixed;
  bottom: 0;
  height: 80vh !important;
  top: 5rem;
  top: 150px;
}

.video-responsive {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
}

.video-responsive-mobile {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  z-index: 999;
}

.video-responsive .iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.beeping-container {
  position: relative;
}

.beeping-animation {
  position: absolute;
  top: -1px;
  left: -2px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 12px;
  background-color: rgb(255, 207, 207); /* Set background color to red for demonstration */
  animation: beeping 1s infinite alternate;
}

@keyframes beeping {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ql-editor {
  font-size: 13px;
}

.donatenow-container {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  height: 80px;
  left: auto;
  right: 0px;
  width: 100%;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-transform: none;
  background-color: 'white';
}
